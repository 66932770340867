import classNames from 'classnames';
import { ReactNode } from 'react';
import ReactModal from 'react-modal';

export interface ModalProps {
  isVisible: boolean;
  children: ReactNode;

  handleToggle(payload?: any): void;

  className?: string;
  overlayClassName?: string;
  scrollContent?: boolean;
  wrapperClassName?: string;
}

export const Modal = ({
  isVisible,
  children,
  handleToggle,
  className,
  overlayClassName = 'bg-[rgba(0,_0,_0,_0.5)]',
  scrollContent,
  wrapperClassName
}: ModalProps) => {
  return (
    <ReactModal
      isOpen={isVisible}
      onRequestClose={() => handleToggle()}
      overlayClassName={classNames(
        'z-40 shadow-[1px_0_rgb(#747474)] cursor-pointer fixed inset-0',
        overlayClassName
      )}
      className={classNames('cursor-default p-0', className, {
        'h-full overflow-auto': scrollContent
      })}
    >
      <div className={wrapperClassName}>{children}</div>
    </ReactModal>
  );
};
