import { useEffect, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import classNames from 'classnames';
import { GetProductsByCategoryDocument } from '@generated/graphql';
import isEmpty from 'lodash/isEmpty';
import { ProductsSlider } from '../ProductsSlider';
import { useGTMDataLayer } from '@hooks';
import { useCustomAttributes } from 'src/hooks/useProductFullDetail';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import SkeletonComponent from '../Skeleton';

interface ProductsSectionProps {
  className?: string;
  classShowcase?: string;
  title: string;
  categoryName?: string;
  subTitle: string;
  categoryIds?: any[];
  items?: any[];
  categoryList?: any[];
}

export const ProductsSection = ({
  categoryIds,
  title,
  categoryName,
  classShowcase,
  className,
  subTitle,
  items,
  categoryList
}: ProductsSectionProps) => {
  const { pushToDataLayer } = useGTMDataLayer({ runOnce: true });
  const pathname = usePathname();

  const {
    metadata,
    loading: loadingCustomAttributes,
    getProductCustomAttributes
  } = useCustomAttributes({ skip: !isEmpty(items) });

  const { data, loading } = useQuery(GetProductsByCategoryDocument, {
    variables: {
      category_ids: categoryIds,
      page_size: 10
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip: !categoryIds?.length || !!items?.length
  });

  const listDoesntComeFromProps = useMemo(() => {
    return (
      !loadingCustomAttributes && metadata && data?.products?.items.length > 0
    );
  }, [data?.products?.items.length, loadingCustomAttributes, metadata]);

  /**
   * Uses items if provided
   * otherwise wait for metadata and fill with
   * products fetched from category query
   */
  const products = useMemo(() => {
    if (items?.length) {
      return items;
    }

    if (listDoesntComeFromProps) {
      const list = data?.products?.items;
      return list.map(product => ({
        ...product,
        ...getProductCustomAttributes(product)
      }));
    }
    return [];
  }, [
    data?.products?.items,
    getProductCustomAttributes,
    items,
    listDoesntComeFromProps
  ]);

  /**
   * Only triggers dataLayer event when items list is empty
   * this prevents impressions event from being triggered on PDP
   */
  useEffect(() => {
    if (isEmpty(items) && !isEmpty(products)) {
      pushToDataLayer(
        'showcaseProducts',
        {
          list: title,
          products
        },
        false
      );
    }
  }, [items, products, pushToDataLayer, title]);

  const categoryData = useMemo(() => {
    return (
      categoryName &&
      categoryList?.find(categoryItem => {
        return categoryItem.name.toLowerCase() === categoryName.toLowerCase();
      })
    );
  }, [categoryList, categoryName]);

  if (loading) {
    return (
      <section className="my-6 w-full flex flex-col justify-center items-center">
        <SkeletonComponent className="w-[12rem] lg:w-[20rem] h-[28px] mb-1" />
        <SkeletonComponent className="w-[20rem] lg:w-[32rem] h-[36px] lg:h-[28px] mb-6" />

        <div className="w-full flex px-4 lg:items-center lg:justify-center gap-8 overflow-x-scroll lg:overflow-auto">
          {Array.from({ length: 4 }).map((_, index) => (
            <SkeletonComponent height={495} width={276} key={index} />
          ))}
        </div>

        <SkeletonComponent height={24} width="8rem" className="mt-1" />
      </section>
    );
  }

  return (
    <>
      {!isEmpty(products) && (
        <section
          className={classNames(
            `font-normal text-center mb-16 md:px-3 md:mx-16 ${classShowcase}`,
            className
          )}
        >
          {/* Not Happy with this solution, it helps keeping the contrast from the home to affect other screens such as categories - Sugestions? */}
          <h2
            className={`font-bold text-lg lg:text-xl text-primary-dark ${pathname === '/' && 'dark:text-primary-light'}`}
          >
            {title}
          </h2>

          <p
            className={`lg:mt-1 mb-6 px-3 text-base text-gray-dark ${pathname === '/' && 'dark:text-gray-light'}`}
          >
            {subTitle}
            {categoryData && (
              <Link href={`${categoryData?.url_path}/c`}>
                <span className="text-primary-medium text-base ml-2 underline dark:text-primary-light">
                  Ver todos os produtos
                </span>
              </Link>
            )}
          </p>
          <div>
            <ProductsSlider
              products={products}
              categoryData={categoryData}
              onClickItem={(product, position) => {
                pushToDataLayer(
                  'selectItem',
                  { ...product, list: title, position },
                  false
                );
              }}
              settings={{
                showDots: true,
                centerMode: false,
                numberOfSlides: 4,
                numberOfSlidesForMobile: 2
              }}
            />
          </div>
        </section>
      )}
    </>
  );
};

interface ProductsHighlightProps {
  className?: string;
  categoryList?: any[];
}

export const ProductsHighlight = ({
  className,
  categoryList
}: ProductsHighlightProps) => {
  return (
    <ProductsSection
      classShowcase="gtm-produtosDestaque"
      title="Produtos em Destaque"
      categoryName="Destaques Home"
      categoryList={categoryList}
      subTitle="Estas são nossas últimas novidades selecionadas para você!"
      className={className}
      categoryIds={[
        process.env.NEXT_PUBLIC_HOME_HIGHLIGHTED_PRODUCTS_CATEGORY_ID
      ]}
    />
  );
};
