import { gql, useQuery } from '@apollo/client';
import { DynamicImage, Slider } from '@common';
import SkeletonComponent from '@components/common/Skeleton';
import Link from 'next/link';

export const GET_CMS_BLOCKS = gql`
  query cmsBlocks($identifiers: [String]!) {
    cmsBlocks(identifiers: $identifiers) {
      items {
        content
        identifier
      }
      block_content {
        content
        url
        image
        alt_image
      }
    }
  }
`;

const MAX_SLIDE_COUNT = 4;

export const NewsBanner = () => {
  const { data, loading } = useQuery(GET_CMS_BLOCKS, {
    variables: { identifiers: 'news-banner' }
  });

  return (
    <section className="p-2 mb-4 md:p-0 gtm-principaisProdutos">
      {loading ? (
        <div className="w-full flex flex-col items-center justify-center">
          <div className="w-full flex px-4 lg:items-center lg:justify-center gap-8 overflow-x-scroll lg:overflow-auto">
            {Array.from({ length: 4 }).map((_, index) => (
              <SkeletonComponent
                key={index}
                className="w-[264px] h-[354px] lg:w-[324px] lg:h-[432px]"
              />
            ))}
          </div>
          <SkeletonComponent height={24} width="8rem" className="mt-1" />
        </div>
      ) : (
        <>
          <div className="hidden lg:grid grid-cols-4 gap-6 container mx-auto">
            {data?.cmsBlocks?.block_content
              ?.slice(0, MAX_SLIDE_COUNT)
              .map(({ url, image, alt_image }) => (
                <Link
                  href={url}
                  key={image}
                  prefetch={false}
                  className="w-full col-span-2 md:col-span-1 relative rounded overflow-hidden"
                >
                  <DynamicImage
                    desktopSrc={image}
                    mobileSrc={image}
                    desktopProps={{
                      width: 324,
                      height: 432,
                      quality: 60
                    }}
                    alt={alt_image}
                    data-imgmidia={image.match(/https?:\/\/[^/]+(.+)/)[1]}
                  />
                </Link>
              ))}
          </div>

          <div className="lg:hidden w-full mb-6">
            {data?.cmsBlocks?.block_content?.length > 0 && (
              <Slider
                autoPlaySpeed={9000}
                pauseOnHover
                renderDotsOutside={true}
                itemClass="w-auto"
                arrows={false}
                partialVisible
              >
                {data?.cmsBlocks?.block_content?.map(
                  ({ url, image, alt_image }) => (
                    <Link
                      href={url}
                      key={image}
                      prefetch={false}
                      className="w-[68vw] col-span-2 md:col-span-1 relative rounded overflow-hidden min-h-[91vw] block mr-4 mb-1"
                    >
                      <DynamicImage
                        mobileSrc={image}
                        desktopSrc={image}
                        mobileProps={{
                          width: 280,
                          height: 375,
                          quality: 60
                        }}
                        alt={alt_image}
                      />
                    </Link>
                  )
                )}
              </Slider>
            )}
          </div>
        </>
      )}
    </section>
  );
};
