import { gql, useQuery } from '@apollo/client';
import { useMemo } from 'react';

export const GET_BREADCRUMBS = gql`
  query GetBreadcrumbs($category_id: Int!) {
    category(id: $category_id) {
      breadcrumbs {
        category_id
        # We may not need level if \`breadcrumbs\` is sorted.
        category_level
        category_name
        category_url_path
      }
      id
      name
      url_path
      url_suffix
    }
  }
`;

// Just incase the data is unsorted, lets sort it.
const sortCrumbs = (a, b) => a.category_level > b.category_level;

/**
 * Returns props necessary to render a Breadcrumbs component.
 *
 * @param {object} props
 * @param {object} props.query - the breadcrumb query
 * @param {string} props.categoryId - the id of the category for which to generate breadcrumbs
 * @return {{
 *   currentCategory: string,
 *   currentCategoryPath: string,
 *   isLoading: boolean,
 *   normalizedData: array
 * }}
 */
export const useBreadcrumbs = props => {
  const { categoryId } = props;

  const { data, loading, error } = useQuery(GET_BREADCRUMBS, {
    variables: { category_id: categoryId },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  });

  // When we have breadcrumb data sort and normalize it for easy rendering.
  const normalizedData = useMemo(() => {
    if (!loading && data) {
      const breadcrumbData = data.category.breadcrumbs;

      return (
        breadcrumbData &&
        breadcrumbData
          .map(category => ({
            category_level: category.category_level,
            text: category.category_name,
            path: `/${category.category_url_path}/c`
          }))
          .sort(sortCrumbs)
      );
    }
  }, [data, loading]);

  return {
    currentCategory: (data && data.category.name) || '',
    currentCategoryPath: (data && `/${data.category.url_path}/c`) || '#',
    isLoading: loading,
    hasError: !!error,
    normalizedData: normalizedData || []
  };
};
